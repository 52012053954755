'use client';

import { MatchPricePopup } from '@forms/matchPrice';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@ngg/icons';
import type { Form as IForm, Maybe } from '@server/gql/graphql';
import { AnimatePresence, motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { cn } from '@/lib/utils';
import { transformStringToSnakeCase } from '@/utils/common';

import { BookMeetingPopup } from './bookMeeting';
import { NewCustomerPopup } from './newCustomer';
import { PlaceOrderPopup } from './placeOrder';

type Props = {
    buttonContent: ReactNode;
    form?: Maybe<IForm>;
    className?: string;
    classNameButton?: string;
};

const Form = ({ form }: { form?: Maybe<IForm> }) => {
    if (!form) return null;

    const { reference, name } = form;

    if (!name) return null;

    const formName = transformStringToSnakeCase(name);

    if (formName === 'match_price_form' && reference)
        return <MatchPricePopup form={form} />;
    if (formName === 'newsletter_form') return <NewCustomerPopup form={form} />;
    if (formName === 'place_order_form_pdp' && reference)
        return <PlaceOrderPopup form={form} />;
    if (formName === 'book_meeting_form')
        return <BookMeetingPopup form={form} />;

    return null;
};

export default function FormPopup({
    buttonContent,
    form,
    className,
    classNameButton,
}: Props) {
    const pathname = usePathname();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    const isNewCustomerPopup = form?.name === 'Newsletter Form';

    if (!Form) return null;

    return (
        <>
            <div className={className}>
                <button
                    type="button"
                    className={cn(
                        'inline-block underline underline-offset-2',
                        classNameButton,
                    )}
                    onClick={() => setIsOpen(true)}>
                    {buttonContent}
                </button>
            </div>

            <AnimatePresence>
                {isOpen && (
                    <Dialog
                        static
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        className="relative z-40">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.15, ease: 'linear' }}
                            className="fixed inset-0 bg-black/70"
                        />

                        <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            transition={{ duration: 0.2, ease: 'easeOut' }}
                            className="fixed inset-0 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4">
                                <Dialog.Panel className="relative">
                                    <button
                                        type="button"
                                        onClick={() => setIsOpen(false)}
                                        className={cn([
                                            'absolute right-0 top-0 p-4',
                                            isNewCustomerPopup && 'text-white',
                                        ])}>
                                        <XIcon className="text-base" />
                                    </button>
                                    <Form form={form} />
                                </Dialog.Panel>
                            </div>
                        </motion.div>
                    </Dialog>
                )}
            </AnimatePresence>
        </>
    );
}
