import type { TailwindClasses } from '@/lib/utils';
import { cn } from '@/lib/utils';

type LinkOutlinedProps = {
    startIcon?: React.ReactNode;
    className?: TailwindClasses;
    children: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;
function LinkOutlined({
    children,
    className,
    startIcon,
    ...rest
}: LinkOutlinedProps) {
    return (
        <a
            className={cn(
                'h-10 ',
                'border border-grey-300',
                'inline-flex items-center justify-center gap-2 ',
                'px-4 py-3',
                'text-center text-sm font-normal normal-case text-black',
                'select-none',
                'hover:bg-white hover:text-black',
                className,
            )}
            {...(rest as any)}>
            <div className="flex items-center">{startIcon}</div>
            {children}
        </a>
    );
}
export default LinkOutlined;
