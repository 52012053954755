import type { Media } from 'types/image';

export default function parseMedia(
    values: string[],
    type: Media['type'],
): Media[] {
    return values.map((value, index) => ({
        src: value,
        id: type + index,
        type,
        alt: `${type} ${index + 1} of ${values.length}`,
        order: index + 1,
    }));
}
