import parseProduct from '@server/utils/parseLoop/parseProduct';
import parseVariant from '@server/utils/parseLoop/parseVariant';
import type { Loop54Response } from '@server/utils/parseLoop/types';
import type { Product, Variant } from 'types/product';

export const parseWishlist = (
    loop54Response: Loop54Response,
): (Product | Variant)[] => {
    const { results } = loop54Response;
    const { items } = results;

    const productsOrVariants = items
        .map((item) => {
            if (item.type === 'Product') return parseProduct(item);
            if (item.type === 'Variant') return parseVariant(item);
        })
        .filter((item): item is Variant | Product => Boolean(item));

    return productsOrVariants;
};
