export default {
    se: 'SEK',
    dk: 'DKK',
    no: 'NOK',
    fi: 'EUR',
    en: 'EUR',
    de: 'EUR',
    at: 'EUR',
    be: 'EUR',
    es: 'EUR',
    fr: 'EUR',
    nl: 'EUR',
    eu: 'EUR',
} as {
    [key: string]: string;
};
