import { useState } from 'react';

export type FileProps = {
    label: string;
    id: string;
};

export default function File({ label, id }: FileProps) {
    const [file, setFile] = useState<File | undefined>(undefined);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const uplodadedFile = event.target.files?.[0];

        if (uplodadedFile) {
            setFile(uplodadedFile);
        }
    }

    function handleRemove() {
        setFile(undefined);
    }

    return (
        <>
            <label className="block text-sm" htmlFor={id}>
                {label}
            </label>
            <div className="grid">
                <button
                    className="col-span-full row-span-full flex w-full flex-col place-items-center gap-y-3 border border-dashed border-grey-300 p-6 text-sm"
                    type="button">
                    {file ? (
                        <>
                            <span>{file.name}</span>
                            <button
                                className="z-10 border px-3 py-1 transition-colors hover:bg-grey-100"
                                onClick={handleRemove}
                                type="button">
                                Remove
                            </button>
                        </>
                    ) : (
                        <>
                            <span>Drag and drop file here</span>
                            <span className="text-grey-300">or</span>
                            <span className="border px-3 py-1">
                                Select files
                            </span>
                        </>
                    )}
                </button>
                <input
                    onChange={handleChange}
                    className="col-span-full row-span-full cursor-pointer opacity-0"
                    name={id}
                    id={id}
                    type="file"
                />
            </div>
        </>
    );
}
