'use client';

import Flag from '@components/Flag';
import { Input, Select, Textarea } from '@components/inputs';
import { markets } from '@context/common';
import { useForm, ValidationError } from '@formspree/react';
import { Button } from '@ngg/components';
import type { Form, KeyValue, Maybe } from '@server/gql/graphql';
import type { Dictionary } from 'types/common';

import { cn } from '@/lib/utils';
import { createDictionary } from '@/utils/common';

const SelectIcon = ({ id }: { id: string }) => (
    <Flag code={id} className="h-full w-full rounded-full object-cover" />
);

const formattedMarkets = Object.values(markets)
    .filter((m) => !m.isIndependentMarket)
    .map((m) => ({
        id: m.code,
        value: m.code,
        label: m.label,
    }));

export default function PlaceOrder({
    dictionary,
    className,
    reference,
}: {
    className?: string;
    dictionary: Dictionary;
    reference: string;
}) {
    const [state, handleSubmit] = useForm(reference);
    const isInputError = (inputName: string) =>
        state?.errors.some((error) => error.field === inputName);

    if (state.succeeded) {
        return (
            <div className={className}>
                <h2 className="text-lg font-bold">
                    {dictionary.placeOrderFormSuccessHeading ||
                        dictionary.formSuccessHeading}
                </h2>
                <p className="mt-4 text-sm">
                    {dictionary.placeOrderFormSuccessText ||
                        dictionary.formSuccessText}
                </p>
            </div>
        );
    }

    return (
        <form
            onSubmit={handleSubmit}
            className={cn('flex flex-col gap-y-6', className)}>
            <h2 className="w-full text-lg font-medium">
                {dictionary.placeOrderFormHeadingPartOne}
                <br />
                {dictionary.placeOrderFormHeadingPartTwo}
            </h2>

            <div className="space-y-3">
                <Input
                    id="name"
                    name="name"
                    type="text"
                    required
                    fullWidth
                    label={
                        dictionary.placeOrderFormNameLabel || dictionary.name
                    }
                    classNames={{
                        input: isInputError('name')
                            ? 'border border-error'
                            : '',
                    }}
                />
                <ValidationError
                    className="mt-2 text-sm"
                    field="name"
                    errors={state.errors}
                />

                <Select
                    name="shippingCountry"
                    placeholder={
                        dictionary.placeOrderFormSelectShippingCountryPlaceholder ||
                        dictionary.selectCountry
                    }
                    options={formattedMarkets}
                    required
                    labelIcon={SelectIcon}
                    optionIcon={SelectIcon}>
                    <ValidationError
                        className="mt-2 text-sm"
                        field="shippingCountry"
                        errors={state.errors}
                    />
                </Select>

                <Input
                    id="email"
                    name="email"
                    type="email"
                    required
                    fullWidth
                    label={
                        dictionary.placeOrderFormEmailLabel || dictionary.email
                    }
                    classNames={{
                        input: isInputError('email')
                            ? 'border border-error'
                            : '',
                    }}
                />
                <ValidationError
                    className="mt-2 text-sm"
                    field="email"
                    errors={state.errors}
                />

                <Textarea
                    name="message"
                    id="message"
                    label={
                        dictionary.placeOrderFormMessageLabel ||
                        dictionary.message
                    }
                    fullWidth
                    resizable="none"
                    classNames={{
                        textarea: isInputError('message')
                            ? 'border border-error'
                            : '',
                    }}
                />
                <ValidationError
                    className="mt-2 text-sm"
                    field="message"
                    errors={state.errors}
                />
            </div>

            <Button
                type="submit"
                isLoading={state.submitting}
                fullWidth
                className="btn-primary">
                {dictionary.placeOrderFormSubmitButtonLabel || dictionary.send}
            </Button>

            {/* Honeypot */}
            <input type="text" name="_gotcha" className="hidden" />
        </form>
    );
}

export function PlaceOrderPopup({ form }: { form?: Maybe<Form> }) {
    const formDictionary = createDictionary(
        form?.dictionaryCollection?.items as KeyValue[],
    );

    return (
        <PlaceOrder
            dictionary={formDictionary}
            className="mx-auto w-full max-w-[420px] bg-white px-4 py-10 pt-12 sm:min-w-[420px] md:px-6"
            reference={form?.reference as string}
        />
    );
}
