'use client';

import { Listbox, Transition } from '@headlessui/react';
import { ArrowDownIcon } from '@ngg/icons';
import { Fragment, useState } from 'react';

import { cn } from '@/lib/utils';

export default function Select({
    children,
    options,
    name,
    required = false,
    disabled = false,
    labelIcon,
    optionIcon,
    preSelectedOption,
    classNames = {},
    placeholder,
}: {
    children?: React.ReactNode;
    placeholder: string;
    name: string;
    options: { id: any; value: string; label: string }[];
    required?: boolean;
    disabled?: boolean;
    labelIcon?: ({
        label,
        id,
        value,
    }: {
        label: string;
        id: any;
        value: string;
    }) => React.ReactNode;
    optionIcon?: ({
        label,
        id,
        value,
    }: {
        label: string;
        id: any;
        value: string;
    }) => React.ReactNode;
    preSelectedOption?: { id: string; value: string; label: string };
    classNames?: {
        wrapper?: string;
        button?: string;
        option?: string;
    };
}) {
    const [selectedOption, setSelectedOption] = useState<
        (typeof options)[0] | null
    >(preSelectedOption || null);

    return (
        <>
            <Listbox
                value={selectedOption}
                onChange={setSelectedOption}
                disabled={disabled}>
                <div
                    className={cn(
                        'relative w-full self-start bg-white text-black',
                        classNames.wrapper,
                    )}>
                    <input
                        type="text"
                        name={name}
                        required={required}
                        disabled={disabled}
                        className="pointer-events-none absolute inset-0 select-none appearance-none bg-transparent text-transparent opacity-0 shadow-none"
                        value={selectedOption?.value ?? ''}
                        readOnly
                        tabIndex={-1}
                        aria-hidden="true"
                        aria-readonly="true"
                        autoComplete="off"
                    />
                    <Listbox.Button
                        className={cn(
                            'group flex h-10 w-full items-center border border-black bg-inherit px-4 text-sm text-inherit',
                            'peer:dirty:invalid:border-error',
                            classNames.button,
                        )}>
                        {() => (
                            <>
                                {selectedOption && labelIcon ? (
                                    <div className="mr-2 h-4 w-4 flex-shrink-0">
                                        {labelIcon(selectedOption)}
                                    </div>
                                ) : null}
                                {selectedOption?.label ? (
                                    <span>{selectedOption?.label}</span>
                                ) : (
                                    <span className="text-grey-300">
                                        {placeholder}
                                    </span>
                                )}
                                <ArrowDownIcon
                                    className={cn([
                                        'ml-auto flex-shrink-0 text-base text-inherit transition-transform group-aria-expanded:-rotate-180',
                                        !selectedOption?.label &&
                                            'text-grey-300',
                                    ])}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Listbox.Options className="absolute top-full z-10 mt-0 max-h-40 w-full -translate-y-px overflow-auto border border-black bg-inherit py-1 pl-0 text-inherit">
                            {options.map((option) => (
                                <Listbox.Option
                                    key={option.id}
                                    value={option}
                                    className={cn(
                                        'flex cursor-pointer items-center px-4 py-1 text-sm space-x-2 ui-active:bg-gray-100',
                                        classNames.option,
                                    )}>
                                    {optionIcon ? (
                                        <div className="mr-2 h-4 w-4 flex-shrink-0">
                                            {optionIcon(option)}
                                        </div>
                                    ) : null}
                                    {option.label}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
            {children}
        </>
    );
}
