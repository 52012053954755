'use client';

import { Input } from '@components/inputs';
import Link from '@components/Link';
import { useMarket } from '@context/marketContext';
import { useForm, ValidationError } from '@formspree/react';
import { Button } from '@ngg/components';
import type { Form, KeyValue, Maybe } from '@server/gql/graphql';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type { Dictionary } from 'types/common';

import currencies from '@/config/currencies';
import { createDictionary } from '@/utils/common';

export default function MatchPrice({
    dictionary,
    reference,
    children,
}: {
    dictionary: Dictionary;
    reference: Maybe<string> | undefined;
    children?: ReactNode;
}) {
    const [state, handleSubmit] = useForm(reference || '');
    const [uploadedFiles, setUploadedFiles] = useState<FileList | null>(null);
    const { language, locale } = useMarket().state;
    const currency = currencies[language.code];

    if (state.succeeded) {
        return (
            <>
                <h2 className="text-lg font-bold">
                    {dictionary?.matchPriceFormSuccessHeading}
                </h2>
                <p className="mt-4 text-sm">
                    {dictionary?.matchPriceFormSuccessText}
                </p>
            </>
        );
    }

    const isInputError = (inputName: string) =>
        state?.errors.some((error) => error.field === inputName);

    return (
        <>
            {children}
            <form
                onSubmit={handleSubmit}
                className="not-prose grid max-w-4xl grid-cols-1 gap-x-3 md:grid-cols-2"
                encType="multipart/form-data">
                <div className="space-y-3 md:mt-7">
                    <Input
                        type="text"
                        id="productVariant"
                        label={
                            dictionary?.matchPriceFormProductVariantInputLabel
                        }
                        name="Product Variant"
                        fullWidth
                        classNames={{
                            input: isInputError('Product Variant')
                                ? 'border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Product Variant"
                        errors={state.errors}
                    />
                    <Input
                        type="number"
                        id="price"
                        name="Price"
                        label={dictionary?.matchPriceFormPriceInputLabel}
                        required
                        fullWidth
                        endAdornment={
                            <span className="absolute bottom-2 right-2 top-2 flex items-center p-2 text-sm font-bold uppercase">
                                {currency}
                            </span>
                        }
                        classNames={{
                            input: isInputError('Price') ? 'border-error' : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Price"
                        errors={state.errors}
                    />
                    <Input
                        type="text"
                        id="name"
                        name="Name"
                        label={dictionary?.matchPriceFormNameInputLabel}
                        required
                        fullWidth
                        classNames={{
                            input: isInputError('Name') ? 'border-error' : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Name"
                        errors={state.errors}
                    />
                    <Input
                        type="email"
                        id="email"
                        name="Email"
                        label={dictionary?.matchPriceFormEmailInputLabel}
                        required
                        fullWidth
                        classNames={{
                            input: isInputError('Email') ? 'border-error' : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Email"
                        errors={state.errors}
                    />
                </div>

                <hr className="my-6 bg-black/25 md:hidden" />

                <div className="space-y-3">
                    <p className="text-xs">
                        {dictionary?.matchPriceFormLinkToResellerInputText}
                    </p>

                    <Input
                        type="url"
                        id="linkToReseller"
                        name="Link To Reseller"
                        label={
                            dictionary?.matchPriceFormLinkToResellerInputLabel
                        }
                        fullWidth
                        classNames={{
                            wrapper: 'mt-2',
                            input: isInputError('Link To Reseller')
                                ? 'border-error'
                                : '',
                        }}
                    />
                    <ValidationError
                        className="mt-2 text-sm"
                        field="Link To Reseller"
                        errors={state.errors}
                    />

                    <div className="relative">
                        <Input
                            type="text"
                            readOnly
                            label={
                                uploadedFiles?.length
                                    ? uploadedFiles[0].name
                                    : dictionary?.matchPriceFormUploadInputLabel
                            }
                            fullWidth
                            classNames={{
                                label: uploadedFiles?.length
                                    ? 'text-black'
                                    : '',
                                input: isInputError('Uploaded Offer')
                                    ? 'border-error'
                                    : '',
                            }}
                            endAdornment={
                                <div className="pointer-events-none absolute bottom-2 right-2 top-2 flex items-center bg-black p-2 text-xs font-medium text-white">
                                    {dictionary?.formUploadEndAdornmentText}
                                </div>
                            }
                        />

                        <input
                            name="Uploaded Offer"
                            id="file"
                            type="file"
                            onChange={(e) => setUploadedFiles(e.target.files)}
                            className="absolute inset-0 opacity-0"
                        />
                        <ValidationError
                            className="mt-2 text-sm"
                            field="Uploaded Offer"
                            errors={state.errors}
                        />
                    </div>

                    <Button
                        type="submit"
                        disabled={state.submitting}
                        fullWidth
                        className="btn-primary">
                        {dictionary?.formSendButtonTextMatchPrice}
                    </Button>

                    <p className="mt-4 text-sm">
                        {dictionary?.matchPriceFormTermsText}
                    </p>

                    <p className="mt-2 text-sm">
                        <Link
                            locale={locale}
                            href={dictionary?.matchPriceFormReadMoreLink}
                            className="underline">
                            {dictionary?.matchPriceFormReadMoreLinkText}
                        </Link>
                    </p>
                </div>

                {/* Honeypot */}
                <input type="text" name="_gotcha" className="hidden" />
            </form>
        </>
    );
}

export function MatchPricePopup({ form }: { form: Maybe<Form> | undefined }) {
    const formDictionary = createDictionary(
        form?.dictionaryCollection?.items as Array<KeyValue>,
    );

    return (
        <div className="max-w-[800px] bg-white px-4 py-12 md:px-6">
            <MatchPrice dictionary={formDictionary} reference={form?.reference}>
                <div className="mb-6 grid grid-cols-1 gap-x-3 md:mb-1 md:grid-cols-2">
                    <div>
                        <h2 className="text-lg font-bold">
                            {formDictionary?.matchPriceFormHeading}
                        </h2>

                        <p className="mt-3 text-sm">
                            {formDictionary?.matchPriceFormInfoText}
                        </p>
                    </div>
                </div>
            </MatchPrice>
        </div>
    );
}
