import type { InputBaseProps } from '@ngg/components';
import { Input as NGGInput } from '@ngg/components';
import { forwardRef } from 'react';

import { cn } from '@/lib/utils';

type InputBasePropsWithPassword = InputBaseProps & {
    passwordrules?: string;
};

const Input = forwardRef<HTMLInputElement, InputBasePropsWithPassword>(
    ({ label, id, classNames, endAdornment, required, ...props }, ref) => {
        const isInvalid = props['aria-invalid'] === true;
        return (
            <NGGInput
                ref={ref}
                required={required}
                placeholder={label}
                endAdornment={
                    <>
                        {label ? (
                            <label
                                htmlFor={id}
                                className={cn(
                                    'pointer-events-none absolute bottom-2.5 left-4 top-2.5 text-sm text-grey-300',
                                    'transition-transform peer-focus:-translate-y-1.5 peer-focus:text-xxs',
                                    'peer-[&:not(:placeholder-shown)]:-translate-y-1.5 peer-[&:not(:placeholder-shown)]:text-xxs',
                                    isInvalid && 'text-error',
                                    classNames?.label,
                                )}>
                                {label}
                                {required && '*'}
                            </label>
                        ) : null}
                        {endAdornment}
                    </>
                }
                classNames={{
                    ...classNames,
                    hint: cn(
                        'pl-4',
                        isInvalid && 'text-error',
                        classNames?.hint,
                    ),
                    input: cn(
                        'peer h-10 w-full border border-black px-4 pb-1.5 pt-4 text-base lg:text-sm',
                        'bg-white placeholder:text-transparent',
                        isInvalid && 'border-error',
                        classNames?.input,
                    ),
                }}
                {...props}
            />
        );
    },
);

export default Input;
