import { KEYS } from '@server/utils/parseLoop/const';
import type { Item } from '@server/utils/parseLoop/types';
import type { Image, Media } from 'types/image';
import type { Product } from 'types/product';

import parseMedia from './parseMedia';

export default function parseProduct({ attributes, id }: Item): Product {
    const product: Partial<Product> = {
        type: 'Product',
        media: [] as Media[],
    };

    for (let i = 0; i < attributes?.length; i += 1) {
        const { name, values } = attributes[i];
        if (name === KEYS.ID_KEY) {
            product.id = values[0] as string;
            product.slug = values[0] as string;
        } else if (name === KEYS.NAME_KEY) product.title = values[0] as string;
        else if (name === KEYS.BRAND_KEY) product.brand = values[0] as string;
        else if (name === KEYS.DESIGNER_KEY)
            product.designer = values[0] as string;
        else if (name === KEYS.DESIGNERS_KEY)
            product.designers = values as string[];
        else if (name === KEYS.GROUP_KEY) product.groupId = values[0] as string;
        else if (name === KEYS.DESCRIPTION_KEY)
            product.description = values[0] as string;
        else if (name === KEYS.CATEGORY_KEY)
            product.category = values[0] as string;
        else if (name === KEYS.STOCK_KEY)
            product.hasStock = values[0] as boolean;
        else if (name === KEYS.FLAGS_KEY) product.flags = values as string[];
        else if (name === KEYS.NUMBER_OF_VARIANTS_KEY)
            product.hasVariants = (values[0] as number) > 1;
        else if (name === KEYS.CAMPAIGN_KEY)
            product.hasCampaign = values[0] as boolean;
        else if (name === KEYS.RELATED_PRODUCTS_KEY)
            product.relatedProducts = values as string[];
        else if (name === KEYS.RELATED_LIGHTSOURCES_KEY)
            product.relatedLightsources = values as string[];
        else if (name === KEYS.RECOMMENDED_LIGHTSOURCES_KEY)
            product.recommendedLightsources = values as string[];
        else if (name === KEYS.NEXT_DELIVERY_DATE)
            product.nextDeliveryDate = new Date(values[0] as string);
        else if (name === KEYS.LIGHTSOURCES_QUANTITY_KEY)
            product.lightsourceQuantity = Number(values[0]);
        else if (name === KEYS.PRICE_KEY) {
            if (values.length > 1) product.fromPrice = true;
            product.price = Math.min(...(values as number[])) as number;
        } else if (name === KEYS.FROM_PRICE_KEY) {
            product.fromPrice = true;
            product.price = Math.min(...(values as number[])) as number;
        } else if (name === KEYS.ORIGINAL_PRICE_KEY) {
            product.campaignPrice = true;
            product.originalPrice = Math.min(...(values as number[])) as number;
        } else if (name === KEYS.PRESENTATION_PRICE_KEY) {
            product.presentationPrice = Math.min(
                ...(values as number[]),
            ) as number;
        } else if (name === KEYS.PRESENTATION_PRICE_TYPE_KEY) {
            product.priceType = values.at(0) as string;
        } else if (name === KEYS.PRESENTATION_ORIGINAL_KEY) {
            product.presentationOriginal = Math.min(
                ...(values as number[]),
            ) as number;
        } else if (name === KEYS.VAT_RATE_KEY) {
            product.vatRate = Math.min(...(values as number[])) as number;
        }
        // else if (name === KEYS.CAMPAIGN_PRICE_KEY) product.campaignPrice = values[0] as number;
        else if (name === KEYS.VARIANT_ATTRIBUTES_KEY) {
            const entries = Object.entries(JSON.parse(values[0] as string));
            product.variantAttributes = entries.map(([key, value]) => ({
                key,
                ...(value as any),
            }));
        } else if (name === KEYS.STOCK_STATUS_KEY) {
            product.stockStatus = values[0] as string;
            product.stock = values[0] === 'StockItem';
            // } else if (name === KEYS.FROM_PRICE_KEY) {
            //     product.price = values[0] as number;
            //     product.fromPrice = true;
            // } else if (name === KEYS.CAMPAIGN_FROM_PRICE_KEY) {
            //     product.campaignPrice = Number(values[0]);
            //     product.campaignFromPrice = true;
        } else if (name === KEYS.IMAGE_KEY) {
            product.images = values.map(
                (src, index): Image => ({
                    src: src as string,
                    id: `${id} #${index}`,
                    type: 'image',
                    alt: '',
                }),
            );
            product.media = [
                ...parseMedia(values as string[], 'image'),
                ...(product.media as Media[]),
            ];
        } else if (name === KEYS.VIDEO_KEY) {
            product.media = [
                ...(product.media as Media[]),
                ...parseMedia(values as string[], 'video'),
            ];
        } else product[name] = values.length > 1 ? values : values[0];
    }

    return product as Product;
}
