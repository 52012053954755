'use client';

import type { TextareaProps } from '@ngg/components';
import { Textarea as NGGTextarea } from '@ngg/components';

import { cn } from '@/lib/utils';

export default function Textarea({
    classNames,
    endAdornment,
    label,
    id,
    required,
    placeholder = '',
    ...props
}: TextareaProps) {
    return (
        <NGGTextarea
            {...props}
            required={required}
            placeholder={placeholder}
            id={id}
            endAdornment={
                <>
                    {label ? (
                        <label
                            htmlFor={id}
                            className={cn(
                                'pointer-events-none absolute bottom-2.5 left-4 top-2.5 text-sm text-grey-300 transition-transform peer-focus:-translate-y-1.5 peer-focus:text-xxs peer-[&:not(:placeholder-shown)]:-translate-y-1.5 peer-[&:not(:placeholder-shown)]:text-xxs',
                                classNames?.label,
                            )}>
                            {label}
                            {required && '*'}
                        </label>
                    ) : null}
                    {endAdornment}
                </>
            }
            classNames={{
                ...classNames,
                textarea: cn(
                    'peer h-32 w-full border border-black px-4 py-3.5 text-sm placeholder:text-transparent focus:placeholder:text-grey-300',
                    classNames?.textarea,
                ),
            }}
        />
    );
}
