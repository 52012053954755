import type { HTMLProps } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';

import { cn } from '@/lib/utils';
import { formatPrice } from '@/utils/priceUtils';

const REGEXP_TRAILING_ZEROS = /^0+/;

const removeLeadingZero = (str: string) =>
    str.length > 1 ? str.replace(REGEXP_TRAILING_ZEROS, '') : str;

type Props<T> = T & {
    value: number;
    locale: string;
    suffix?: string | null;
    alignLabel?: 'left' | 'right';
};

export default function Number(props: Props<HTMLProps<HTMLInputElement>>) {
    const {
        id,
        max,
        min,
        name,
        label,
        suffix,
        locale,
        className,
        alignLabel = 'left',
        value,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const validateChange: React.ChangeEventHandler<HTMLInputElement> =
        useCallback(
            (event) => {
                if (inputRef.current === null) {
                    return;
                }

                if (inputRef.current.value === '') {
                    inputRef.current.placeholder = '';
                }

                inputRef.current.value = removeLeadingZero(event.target.value);
            },
            [inputRef],
        );

    const displayValue = useMemo(() => {
        if (!suffix) return value;
        if (suffix === '%CURRENCY%') return formatPrice(value, locale);
        return `${value} ${suffix}`;
    }, [value, suffix, locale]);

    return (
        <>
            <label
                htmlFor={id}
                className={cn(
                    'w-full',
                    'text-xxs',
                    alignLabel === 'left' ? 'text-left' : 'text-right',
                )}>
                {label}
            </label>
            <div
                className={cn(
                    'relative flex flex-col items-stretch justify-center transition-all ease-out',
                )}>
                <input
                    defaultValue={value}
                    name={name}
                    ref={inputRef}
                    id={id}
                    type="number"
                    className={cn(
                        'peer block w-full p-1 placeholder-black',
                        'text-center text-sm',
                        'border border-black',
                        className,
                    )}
                    onChange={validateChange}
                    min={Math.ceil(min as number)}
                    max={Math.ceil(max as number)}
                />
                <div
                    className={cn(
                        'pointer-events-none absolute bottom-1 left-3 whitespace-nowrap bg-white text-sm transition-all peer-focus-within:hidden',
                    )}>
                    {displayValue}
                </div>
                <input
                    size={String(displayValue).length}
                    className="invisible block h-0 px-1 text-sm"
                    value={displayValue}
                />
            </div>
        </>
    );
}
